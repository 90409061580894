import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";

const Cookies = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              
              <Link to="/contact">
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Let's talk
              </button>
              </Link>
            </>
          ),
          footerStyle: "style2",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11 mb-14">
                <div className="text-center">
                  <h2 className="font-size-10 mb-8">Our Cookie Policy</h2>
                  <p className="font-size-7 mb-0">
                  Last Modified: June 8, 2022
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-10 pr-lg-13 pr-xl-0 border-top">
                  <div className="pt-lg-17 pt-8">
                    <p className="font-size-6 mb-0">
                    We use cookies to help improve your experience of our website at <a href="#">https://cloudstackai.com</a>. This cookie policy is part of Cloudstack Automation Pvt Ltd's privacy policy. It covers the use of cookies between your device and our site.<br/>

                    <br/>We also provide basic information on third-party services we may use, who may also use cookies as part of their service. This policy does not cover their cookies.<br/> 

                    <br/>If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from https://saleskraft.com. In such a case, we may be unable to provide you with some of your desired content and services.<br/></p>
                  </div>
                  <div className="mt-9 mt-lg-11">
                      <h6 className="font-size-8 mb-7">What is a cookie?</h6>
                      <p className="font-size-6 ">
                      A cookie is a small piece of data that a website stores on your device when you visit. It typically contains information about the website itself, a unique identifier that allows the site to recognize your web browser when you return, additional data that serves the cookie’s purpose, and the lifespan of the cookie itself.
                        </p>
                        <p className="font-size-6 ">
                        Cookies are used to enable certain features (e.g. logging in), track site usage (e.g. analytics), store your user settings (e.g. time zone, notification preferences), and to personalize your content (e.g. advertising, language).
                        </p>
                        <p className="font-size-6 ">
                        Cookies set by the website you are visiting are usually referred to as first-party cookies. They typically only track your activity on that particular site.
                        </p>
                        <p className="font-size-6 ">
                        Cookies set by other sites and companies (i.e. third parties) are called third-party cookies They can be used to track you on other websites that use the same third-party service.
                        </p>
                      

                      <div>
                      <h6 className="font-size-8 mb-7">Types of cookies and how we use them</h6>
                      <h6 className="font-size-8 mb-7">Essential cookies</h6>

                      <p className="font-size-6">
                      Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts, and payment processing.</p>

                      <p className="font-size-6">We use essential cookies to enable certain functions on our website.</p>
                      </div>

                      <div>
                      <h6 className="font-size-8 mb-7">Performance cookies</h6>
                      <p className="font-size-6">
                      Performance cookies track how you use a website during your visit. Typically, this information is anonymous and aggregated, with information tracked across all site users. They help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience’s overall website experience. These cookies may be set by the website you’re visiting (first-party) or by third-party services. They do not collect personal information about you.</p>

                      <p className="font-size-6">We use performance cookies on our site.</p>
                      </div>

                      <div>
                      <h6 className="font-size-8 mb-7">Accuracy of Materials</h6>
                      <p className="font-size-6">
                      The materials appearing on our website are not comprehensive and are for general information purposes only. Cloudstack Automation Pvt Ltd does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website.</p>
                      </div>

                      <div>
                      <h6 className="font-size-8 mb-7">Functionality cookies</h6>
                      <p className="font-size-6">
                      Functionality cookies are used to collect information about your device and any settings you may configure on the website you’re visiting (like language and time zone settings). With this information, websites can provide you with customized, enhanced, or optimized content and services. These cookies may be set by the website you’re visiting (first-party) or by third-party services.</p>

                      <p className="font-size-6">
                      We use functionality cookies for selected features on our site.</p> 
                      </div>

                      <div>
                      <h6 className="font-size-8 mb-7">Targeting/advertising cookies</h6>
                      <p className="font-size-6">
                      Targeting/advertising cookies help determine what promotional content is most relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the website you’re visiting (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same third-party service.</p>
                      <p className="font-size-6">
                      We use targeting/advertising cookies on our site.</p>
                      
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </PageWrapper>
    </>
  );
};
export default Cookies;